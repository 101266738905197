<template>
    <div>
        <h1>Spending By Budget</h1>
        <column-chart :data="barGraphData" :colors="colors"></column-chart>
    </div>
</template>

<script>

export default {

  props: {
    budgets: Array,
  },

  computed: {
    barGraphData: function(){
        let data = [];
        for (const budget of this.budgets){
            data.unshift([budget.name + " spent", budget.amount_spent / 100]);
            data.unshift([budget.name, budget.amount_budgeted / 100]);
        }
        return data;
    },

    colors: function(){
      let colors = [];
      for (const budget of this.budgets){
          colors.push("#6d4aff");
          colors.push("#67e6e5");
      }
      return [colors];
    }
  }

}
</script>

<style scoped>

</style>
