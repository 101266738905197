<template>
  <div class="budget-container">
     
     <NotificationPopup ref='popup'></NotificationPopup>

      <!-- ADD CATEGORIES MODAL -->
      <Modal ref="addCategoryModal" :title="'Add Category'"
              :submitLabel="'Add'" :submitButtonClass="'btn btn-primary'"
              @submit='addCategory'>
        <FormInputText ref='newCategoryInput' :label="'Category'" :required='true'></FormInputText>
        <FormMessage ref='newCategoryMessage'></FormMessage>
      </Modal>

      <div>
          <!-- PAGE HEADER -->
          <div class="header-container">

            <div class="breadcrumb-container">
              <div class="breadcrumb-link font-link" @click="$emit('back')">Budgets</div>
              <div class="breadcrumb-divider">/</div>
              <BreadCrumbSelect ref='budgetSelector' 
                class="budget-selector" @change='budgetChanged'>
              </BreadCrumbSelect>
            </div>

            <div class="add-button-container">
              <button class="btn btn-large btn-primary" @click='openAddCategoryModal'>Add Category</button>
            </div>

          </div>

          <!-- SUMMARY ROW -->
          <div class="summary-row ">
            <!-- Date -->
            <div v-if="!editMode" class="flex-row">
              <div class="summary-label">Dates:</div>
              <div v-if="budget.start_date" class="summary-value">
                {{ budget.start_date }} &nbsp;-&nbsp; {{ budget.end_date }}
              </div>
            </div>
            <!-- Edit Date -->
            <div v-if='editMode' class='flex-row'>
              <FormInputDate ref="editStartDate" class='edit-date-item'
                :initialDate='budget.start_date'
                @change='dateChanged'></FormInputDate>
              <FormInputDate ref="editEndDate" class='edit-date-item'
                :initialDate='budget.end_date'
                @change='dateChanged'></FormInputDate>
            </div>
            <!-- Totals Summary-->
            <div v-if="budget.categories.length > 0" class="flex-row">
              <div class="summary-label">Budgeted: </div>
              <div class="summary-value">{{ formatMoney(totalBudgeted / 100) }} </div> 
              <div class="summary-label">Spent: </div>
              <div class="summary-value">{{ formatMoney(totalSpent / 100) }}  </div>
              <div class="summary-label">Remaining: </div>
              <div class="summary-value" :class="{'font-red': totalSpent > totalBudgeted, 'font-green': totalSpent <= totalBudgeted}">              
                {{ formatMoney((totalBudgeted - totalSpent) / 100) }}  
            </div>
         </div>
      </div>

          <!-- TABLE CONTROL ITEMS -->
          <div class="icon-button-container">
                <IconButton :icon="'fas fa-redo'" @click="loadBudget" title='Refresh Budget'></IconButton>
                <IconButton class='edit-btn' :icon="'fas fa-edit'" @click="toggleEditMode" title='Toggle Edit Mode'></IconButton>
                <IconButton :icon="'fas fa-trash-alt'" @click="deleteCategories" title='Delete Selected Categories'></IconButton>
                <IconButton :disabled="!edited" :icon="'fas fa-save'"  @click='save'></IconButton>
                <div class="font-medium save-text" ref="">{{autoSaveMessage}}</div>
                <div v-if="autoSaved" class="flex-row">
                  <i class="fas fa-check font-green"></i>
                </div>

          </div>
          
          <!-- CATEGORY TABLE -->
          <div class='budget-category-table-container'>
            <BudgetCategoryTable v-for="category in budget.categories" 
              ref='categoryTables'
              :key='category.name'
              :category='category'
              :editMode='editMode'
              @category-edited='edited = true'
              @open-transactions='openTransactions'
              @category-dragged="categoryDragged">
            </BudgetCategoryTable>

            <!-- NO CATEGORIES MESSAGE -->
            <div v-if="budget.categories.length == 0" class="text-align-center">
              <h2>No Categories Found</h2>
              <div>Add some categories using the 'Add Category' button up above</div>
              <br>
            </div>
          </div>
          
      </div>

  </div>
</template>

<script>

import Modal from '../components/Modal'
import IconButton from '../components/IconButton'
import FormInputText from '../components/FormInputText'
import FormInputDate from '../components/FormInputDate'
import FormMessage from '../components/FormMessage'
import BreadCrumbSelect from '../components/BreadCrumbSelect'
import NotificationPopup from '../components/NotificationPopup'
import BudgetCategoryTable from '../components/BudgetCategoryTable'

import ServiceUtils from '../utilities/ServiceUtils'
import FormatUtils from '../utilities/FormatUtils'

export default {
  mixins: [ServiceUtils, FormatUtils],

  components: {
    Modal,
    IconButton,
    FormInputText,
    FormInputDate,
    FormMessage,
    BreadCrumbSelect,
    BudgetCategoryTable,
    NotificationPopup,
  },

  data() {
    return {
      budget: {
        categories: []
      },
      editMode: false,
      edited: false,
      autoSaved: false,
      autoSaveMessage: ""
    }
  },

  mounted: function() {
    this.loadBudgetSelectorOptions();
  },

  methods: {

    dateChanged: function(){
      this.edited = true;
      this.budget.start_date = this.$refs.editStartDate.getValue();
      this.budget.end_date = this.$refs.editEndDate.getValue();
    },

    loadBudgetSelectorOptions: function() {
      this.get('/budgets-all', {}, function(budgets){
        budgets.sort((a,b) => (a.start_date < b.start_date) ? 1 : -1)
        var options = []
        for(var budget of budgets) {
          options.push(
            {label: budget.name, value: budget.id}
          )  
        }
        this.$refs.budgetSelector.options = options;
      }.bind(this), function(error){
        console.log(error)
        this.$refs.popup.open("Could not load budgets.", true);
      }.bind(this))
    },

    openTransactions: function(filterOptions) {
      filterOptions["start_date"] = this.budget.start_date;
      filterOptions["end_date"] = this.budget.end_date;
      this.$emit('open-transactions', filterOptions);
    },

    amountRemaining: function() {
      return (this.budget.amount_budgeted - this.budget.amount_spent) / 100;
    },

    budgetChanged: function(){
      var id = this.$refs.budgetSelector.getValue()
      this.loadBudget(id);
    },

    loadBudget: function(budget_id){
      budget_id = (budget_id)? budget_id : this.budget.id;
      this.$refs.budgetSelector.setValue("" + budget_id);

      this.get('/budgets/' + budget_id, {}, function(budget){
        this.setBudget(budget)
      }.bind(this), function(error){
        this.$refs.popup.open(error, true);
      }.bind(this))
    },

    save: function(callback){
      this.put('/budgets/' + this.budget.id, this.budget, function(){
        this.editMode = false;
        this.edited = false;
        callback()
      }.bind(this), function(error){
        this.$refs.popup.open(error, true);
      }.bind(this))
    },

    setBudget: function(budget){
      if(budget){
        this.budget = budget
      } else {
        this.budget = {categories: []}
      }

      this.edited = false;
      this.editMode = false;
      this.autoSaved = false;

      this.loadBudgetSelectorOptions();
    },

    openAddCategoryModal: function(){
      this.$refs.addCategoryModal.open();
    },

    addCategory: function(){
      var category = this.$refs.newCategoryInput.getValue();

      if(category && category != ""){
        this.$refs.addCategoryModal.close();
        
        this.budget.categories.push({
          name: category,
          sub_categories: []
        });
  
        this.edited = true;

      } else {
        this.$refs.newCategoryMessage.setErrorMessage("Cannot be empty")
      }

    },

    deleteCategories: function(){
      for (const table of this.$refs.categoryTables) {
        var selected = table.getSelected();
        
        if (selected.length === table.category.sub_categories.length) {
          this.budget.categories = this.budget.categories
            .filter(c => c.name != table.category.name);
          this.edited = true;
        } else if (selected.length > 0){
          table.category.sub_categories = table.category.sub_categories
            .filter(sub => selected.includes(sub.name) === false);
          this.edited = true;
        }
      }

      
    },

    toggleEditMode: function() {
      this.editMode = !this.editMode;
    },

    categoryDragged: function(target, category) {
      for (const table of this.$refs.categoryTables){
        if (table.$el.contains(target)){
          let from = this.budget.categories.indexOf(category);
          let to = this.budget.categories.indexOf(table.category);
          if (from != to){
            const copy = this.budget.categories.splice(from, 1)[0];
            this.budget.categories.splice(to, 0, copy);
            this.edited = true;
          }
        }
      }

      
    }

  },

  watch: {
    edited: function(){
      if (this.edited === true){
          this.autoSaveMessage = "saving ..."
          setTimeout(() => {
            this.save(() => {
              var timestamp = new Date().toLocaleTimeString(); 
              this.autoSaveMessage = "saved " + timestamp;
              this.autoSaved = true;
            });
          }, 500);

      }
    }
  },

  computed: {
    totalSpent: function(){
      var total = 0;
      for(var category of this.budget.categories){
        for (var sub_category of category.sub_categories){
          total += sub_category.amount_spent;
        }
      }
      return total;
    },

    totalBudgeted: function(){
      var total = 0;
      for(var category of this.budget.categories){
        for (var sub_category of category.sub_categories){
          total += sub_category.amount_budgeted;
        }
      }
      return total;
    },

  }

}
</script>

<style scoped>

.budget-category-table-container{
  height: calc(100vh - 19em);
  overflow-y: scroll;
  border-bottom: 1px solid var(--border-secondary);
  padding-bottom: 1em;
}

.budget-container{
  margin-top: 16px;
}


.breadcrumb-link{
  font-size: 14pt;
  font-weight: bold;
  display: inline;
  padding: 4px 0px;
}

.breadcrumb-link:hover{
  text-decoration: underline;
  cursor: pointer;
}

.breadcrumb-container{
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  align-items: center
}

.date-range-container{
  margin-top: 10px;
  margin-bottom: 10px;
}

.breadcrumb-divider{
  font-size: 16pt;
  font-weight: bold;
  padding-left: 12px;
  padding-right: 4px;
  display: inline;
}

.budget-selector{
  max-width: 15em;
  display: inline-block;
}

.budget-selector select{
  background-color: red;
}

.header-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.delete-confirm-text{
  margin-top: 1.0em;
  margin-bottom: 1.67em;
}

.add-button-container{
  display: flex;
  justify-content: flex-end;
}

.show-detail-btn{
  margin-right: 7px;
}

.icon-button-container{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 15pt;
  padding-top: 0.8em;
  padding-bottom: 0.5em;
  border-bottom: 2px solid var(--border-secondary);
}

.edit-date-container{
  display: flex;
  width: 20em;
  margin-bottom: 10px;
}

.edit-date-item {
  margin-right: 10px;
}

.summary-label{
  font-weight: bold;
  margin-right: 7px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.summary-value{
  margin-right: 21px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.save-text{
  padding-top: 5px;
  padding-right: 8px;
}

.summary-row{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
}
</style>
