export default {

    methods: {
        formatMoney: function(value) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            })
    
            return formatter.format(value)
        },

        formatPercent: function(value) {
            return (value * 100).toPrecision(3)  + "%";
        }

    },
}