<template>
  <div>
    <h1>Spending By Category</h1>
    <div class="flex-row">
      <vc-donut
          background="var(--background-primary)" 
          foreground="var(--background-secondary)"
          :size="140" unit="px" :thickness="30"
          :sections="category_sections" :total="total_spending"
          :start-angle="0" :auto-adjust-text-size="true">
          <h2>{{formatMoney(total_spending / 100)}}</h2>
        </vc-donut>
        <table class="legend-table">
            <tr v-for="category in category_sections" :key="category.label">
                <td> <div :style="{'background-color': category.color}" class='legend-icon'></div></td>
                <td class="font-bold"> {{ category.label }} </td>
                <td> {{ formatMoney(category.value / 100) }} </td>
                <td> {{ formatPercent(category.value / total_spending) }} </td>
                <td> 
                <div class='link-button hide-on-mobile' @click='openTransactions(category.label)'>Transaction(s)</div> 
                </td>
            </tr>
        </table>
    </div>
  </div>
  </template>
  
  <script>
  
  import FormatUtils from '../../utilities/FormatUtils'
  
  export default {
    mixins: [FormatUtils],
  
    props: {
      transactions: [],
    },

    data() {
      return {
        total_spending: 1,
      }
    },

    methods: {
      openTransactions: function(category){
        var filterOptions = {
          'category': category,
        }
        this.$emit('open-transactions', filterOptions);
      },
    },
  
    computed: {

      category_sections: function(){
        // var colors = ["#1dbf7b","#3691ef","#d76a59", "#efae38", "#a05dd3", "#1dbf7b"];
        var colors = ["#6d4aff","#67e6e5","#8d78e1", "#efae38", "#a05dd3", "#1dbf7b"];

        var category_spending = Array();
        var total_spent = 0;
        for (var transaction of this.transactions){
          var category = transaction.category.trim();
          if (category_spending[category]){
            category_spending[category] += transaction.amount;         
          } else {
            category_spending[category] = transaction.amount;
          }
          total_spent += transaction.amount;
        }

        var sections = []
        for (const [category, value] of Object.entries(category_spending)) {
          sections.push({
            label: category,
            value: category_spending[category],
          });
        }

        sections.sort((a, b) => {
          return b.value - a.value;
        })

        var color_index = 0;
        for (var section of sections){
          section.color = colors[color_index];
          color_index = (color_index == colors.length - 2)? 1 : color_index + 1;
        }

        this.total_spending = total_spent;

        return sections;
      }

    }
  
  }
  </script>
  
  <style scoped>

.legend-icon{
  height: 11pt;
  width: 11pt;
  border-radius: 4px;
}
  
  .legend-table{
    margin: 8px;
  }
  
  .legend-table td{
    padding: 7px 10px;
  }
  
  </style>
  