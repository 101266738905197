<template>
    <div>
        <NotificationPopup ref='popup'></NotificationPopup>

        <h1>Dashboard</h1>
        <div class='flex-row'>
          <FormInputSelect ref='budgetSelect' :label='"Budget"' 
            :options='budgetOptions'
            @change='budgetsChanged'>
          </FormInputSelect>
          <FormInputDate ref="startDateInput" :label='"Start Date"'
            @change="dateChanged"></FormInputDate>
          <FormInputDate ref="endDateInput" :label='"End Date"'
            @change="dateChanged"></FormInputDate>
          <div class="align-bottom">
            <Button class='btn btn-secondary' @click="loadBudgets">Refresh</Button>
          </div>
          <div class="align-bottom">
          <Button class='btn btn-primary' @click="applyFilter">Apply Filter</Button>
          </div>
        </div>

        <div class="content-divider"></div>

        <div class='flex-row'>
          <BudgetPieChart v-if="selected_budget" :budget="selected_budget"></BudgetPieChart>
          <CategorySpendingPieChart :transactions="transactions" 
            @open-transactions="openTransactions"></CategorySpendingPieChart>
        </div>

        <BudgetsBarGraph v-if="!selected_budget" :budgets="selectedBudgets"></BudgetsBarGraph>
        <SpendingLineGraph :transactions="transactions"></SpendingLineGraph>

    </div>
</template>

<script>
import NotificationPopup from '@/components/NotificationPopup'
import FormInputSelect from '@/components/FormInputSelect'
import FormInputDate from '@/components/FormInputDate'

import ServiceUtils from '@/utilities/ServiceUtils'
import FormatUtils from '@/utilities/FormatUtils'

import BudgetsBarGraph from '@/components/dashboard/BudgetsBarGraph'
import BudgetPieChart from '@/components/dashboard/BudgetPieChart'
import CategorySpendingPieChart from '@/components/dashboard/CategorySpendingPieChart'
import SpendingLineGraph from '@/components/dashboard/SpendingLineGraph'


export default {
  mixins: [ServiceUtils, FormatUtils],

  components: {
    NotificationPopup,
    FormInputSelect,
    FormInputDate,
    BudgetsBarGraph,
    BudgetPieChart,
    CategorySpendingPieChart,
    SpendingLineGraph
},

  data() {
    return {
      budgets: [],
      transactions: [],
      selectedBudgets: [],

      selected_budget: null,
      spendingChartData: {},
    }
  },

  mounted: function(){
    this.loadBudgets(true);
  },


  methods: {

    applyFilter: function(){
      this.loadTransactions();
      this.loadSelectedBudgets();
    },

    openTransactions: function(filterOptions){
      filterOptions.start_date = this.$refs.startDateInput.getValue();
      filterOptions.end_date = this.$refs.endDateInput.getValue();
      this.$emit('open-transactions', filterOptions);
    },

    loadBudgets: function(loadInitialBudget){
      this.get('/budgets', {
        'no_data': true
      }, function(budgets){
        this.budgets = budgets;
        if (loadInitialBudget === true){
          this.loadInitialBudgetOption();
        } else {
          this.budgetsChanged();
        }
      }.bind(this), function(error){
        this.$refs.popup.open("Could not load budgets. Please refresh page", true);
        console.log(error)
      }.bind(this))
    },

    loadInitialBudgetOption: function(){
      var today = new Date();
      today.setHours(0,0,0,0);
      this.selected_budget = this.budgets.filter( function(budget){
        var start = new Date(budget.start_date); //assumes GMT timezone
        var end = new Date(budget.end_date);
        start.setUTCHours(5,0,0);  //Set to CST timezone
        end.setUTCHours(5,0,0);

        return today.getTime() >= start.getTime() &&
          today.getTime() <= end.getTime()
      })[0];

      this.$nextTick(() => {
        this.$refs.startDateInput.setValue(this.selected_budget.start_date);
        this.$refs.endDateInput.setValue(this.selected_budget.end_date);
        this.$refs.budgetSelect.setValue(this.selected_budget.name);
        this.loadTransactions();
      });
    },

    budgetsChanged: function(){
      var current_budget_name = this.$refs.budgetSelect.getValue().trim();
      this.selected_budget = this.budgets.filter(function(budget){
        return budget.name.trim() == current_budget_name
      }.bind(this))[0];

      if (this.selected_budget){
        this.$refs.startDateInput.setValue(this.selected_budget.start_date)
        this.$refs.endDateInput.setValue(this.selected_budget.end_date)
        this.loadTransactions();
      }
    },

    loadTransactions: function(){
      var queryParams = {
        'start_date': this.$refs.startDateInput.getValue(), 
        'end_date': this.$refs.endDateInput.getValue(), 
      }

      this.get('/transactions', queryParams, 
      function(transactions){
        this.transactions = transactions;
      }.bind(this), function(error){
        this.$refs.popup.open(error, true);
        console.log(error)
      }.bind(this))
    },

    dateChanged: function() {
      this.$refs.budgetSelect.setValue(null);
      this.selected_budget = null;
    },

    loadSelectedBudgets: function(){
      let startDate = this.$refs.startDateInput.getValue();
      let endDate = this.$refs.endDateInput.getValue();

      let selected = [];
      for (const budget of this.budgets){
        if (budget.start_date >= startDate && budget.end_date <= endDate){
          selected.push(budget);
        }
      }

      this.selectedBudgets = selected;
    }

  },

  computed: {
    budgetOptions: function(){
      return this.budgets.map(b => b.name);
    }
  }

}
</script>

<style scoped>


.spending-chart{
  margin-bottom: 10em;
  padding: 12px;
  max-width: 75em;
}

.legend-table{
  margin: 10px 24px;
  margin-top: 2.1em;
}

.legend-table td{
  padding: 7px 10px;
}

.legend-icon{
  height: 11pt;
  width: 11pt;
  border-radius: 4px;
  /* border: 1px solid var(--font-secondary); */
}

.flex-row div{
  margin-right: 5px;
  margin-left: 5px;
}

.align-bottom{
  margin-top: auto;
}

.content-divider{
  width: 100%;
  border-top: 2px solid var(--border-secondary);
  margin-top: 2em;
  margin-bottom: 1em;
}


</style>
