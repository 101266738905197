<template>
    <div class="notification-container" @keyup.enter="submit" ref="container"
        :class="{ 'show': show, 'error': isError, 'success': !isError}">
        <div>{{message}}</div>
        <IconButton :icon="'fas fa-times'" @click="close"> </IconButton>
    </div>
</template>

<script>

import IconButton from './IconButton.vue'

export default {

  components: {
    IconButton,
  },

  data() {
      return {
          message: '',
          show: false,
          isError: false,
      }
  },

  methods: {

      open: function(message, isError){
        this.message = message;
        this.isError = isError;
        this.show = true;

        setTimeout(function(){
            this.show = false;
        }.bind(this), 3400)
      },

      close: function(){
          this.show = false;
      }
  }
}
</script>

<style scoped>

.notification-container{
    position: absolute;
    top: -4em;
    left: calc(50vmax - 10em);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 20em;
    padding: 14px 17px;
    padding-right: 8px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.1);
}

.error{
    background-color: #ffdbdb;
    /* border: 2px solid #d45b5b; */
    color: #b84040;
}

.success{
    background-color: #e0f5ed;
    /* border: 2px solid #72a18f; */
    color: #53806f;
}

.standard{
    background-color: var(--background-secondary);
    border: 2px solid var(--border-secondary);
}

.show{
    animation: show 3.5s normal;
}

@keyframes show {
  0% {top: -4em;}
  15% {top: 4em}
  85% {top: 4em;}
  100% {top: -4em;}
}



</style>
