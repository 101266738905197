export default {

    methods: {

        getServiceUrl: function(path){
            return new URL("https://" + window.location.hostname + ':8000' + path);
        },

        post: function(path, body, callback, errorback){
            var url = this.getServiceUrl(path);

            var headers = {
                'Content-Type': 'application/json'
            }

            fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: headers,
                body: JSON.stringify(body),
            })
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()
            }).then(response => {
                callback(response)
            }).catch(this.handleError.bind(this, errorback));
        },

        put: function(path, body, callback, errorback){
            var url = this.getServiceUrl(path);

            var headers = {
                'Content-Type': 'application/json'
            }

            fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: headers,
                body: JSON.stringify(body),
            })
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()
            }).then(response => {
                callback(response)
            }).catch(this.handleError.bind(this, errorback))
        },

        get: function(path, params, callback, errorback){
            var url = this.getServiceUrl(path);
            url.search = new URLSearchParams(params).toString();

            var headers = {
                'Content-Type': 'application/json'
            }

            fetch(url, {
                method: 'GET',
                headers: headers,
                credentials: 'include',
            }).then(response => {
                if (!response.ok) { throw response }
                return response.json()
            }).then(response => {
                callback(response)
            }).catch(this.handleError.bind(this, errorback))
        },

        delete: function(path, callback, errorback){
            var url = this.getServiceUrl(path);

            var headers = {
                'Content-Type': 'application/json'
            }

            fetch(url, {
                method: 'DELETE',
                headers: headers,
                credentials: 'include', 
            }).then(response => {
                if (!response.ok) { throw response }
                return response.json()
            }).then(response => {
                callback(response)
            }).catch(this.handleError.bind(this, errorback))
        },

        handleError: function(errorback, error){

            if (error.status == 401){
                document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                location.reload();
                return;
            }

            if(typeof(error.text) === 'function'){ //if error is promise
                error.text().then( error => {
                    errorback(JSON.parse(error).detail)
                })
            } else {
                console.error(error);
                errorback("Unexpected Error: " + JSON.stringify(error));
            }
        },

        getCookie: function(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
            }
            return null;
          }

    },
}