<template>
    <div>
        <div class='form-input-label' :class="{'disabled': disabled}">
            {{label}}
            <span v-if='required && label' class='required-asterisk'>*</span>
        </div>

        <select ref="input" class='form-input select' 
            :disabled='disabled'
            @change="$emit('change')">
            <!-- TODO make an optional none selected text -->
            <option v-if='!required' selected>(any)</option>
            <option v-for='option in options' :key="_getLabel(option)">
                {{ _getLabel(option) }}
            </option>
            <span>v</span>
        </select>
    </div>
</template>

<script>

export default {
  props: {
      label: String,
      required: Boolean,
      disabled: Boolean,
      placeholder: String,
      options: Array
  },

  methods: {

      setValue: function(value){
        if(value == null || value == "" || value == undefined){
            value = "(any)";
        }
        this.$refs.input.value = value;
      },

      getValue: function(){
          return this.$refs.input.value
      },

      _getLabel: function(option){
          if(option.label != undefined){
              return option.label
          }

          return option
      }
  }
}
</script>

<style scoped>

.placeholder{
    color: red;
}

</style>