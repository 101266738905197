<template>
  <div>
      <NotificationPopup ref='popup'></NotificationPopup>

      <Modal ref="confirmDeleteModal" :title="'Delete Budgets'"  @submit='deleteBudgets'
              :submitLabel="'Delete'" :submitButtonClass="'btn btn-red'">
        <div class='delete-confirm-text'>Woah there partner, are you sure you want to delete these budgets?
          If you delete them they will be gone forever, which is a really long time.
        </div>
      </Modal>

      <Modal ref="addBudgetModal" :title="'New Budget'"  @submit="addNewBudget"
              :submitLabel="'Create'" :submitButtonClass="'btn btn-primary'">
        <FormInputText :label="'Name'" :required='true' ref='budgetNameInput'
          :placeholder="'Name your budget...'"></FormInputText>
        <FormInputSelect ref="copyBudget" label="Copy from a previous budget" :options='copyBudgetOptions'></FormInputSelect>
        <FormInputDate :label="'Start'" :required='true' ref='startDateInput'></FormInputDate>
        <FormInputDate :label="'End'" :required='true' ref='endDateInput'></FormInputDate>
        <FormMessage ref='newBudgetMessage'></FormMessage>
      </Modal>

      <div>

          <div class="header-container">
            <h1>Budgets</h1>
            <div class="add-button-container"> 
              <button class="btn btn-large btn-primary" @click='showAddBudgetModal'>Add Budget</button>
            </div>
          </div>
          
          <Table ref="table" 
              @click-item="budgetItemSelected"
              @delete-items='showDeleteConfirm'
              @refresh='loadBudgets'
              :itemsPerPage='14' itemKey='id' 
              :pagination='true' 
              :clickable='true'>
            <FormInputDate ref="startDate" label="Start"></FormInputDate> 
            <FormInputDate ref="endDate" label="End"></FormInputDate>
            
            <div class='align-bottom'>
              <button class="btn btn-primary" @click='loadBudgets'>Apply Filter</button>
            </div>

          </Table>
      </div>

  </div>
</template>

<script>

import Table from '../components/Table'
import Modal from '../components/Modal'
import FormInputText from '../components/FormInputText'
import FormInputDate from '../components/FormInputDate'
import FormInputSelect from '../components/FormInputSelect'
import FormMessage from '../components/FormMessage'
import ServiceUtils from '../utilities/ServiceUtils'
import NotificationPopup from '../components/NotificationPopup'

export default {
  mixins: [ServiceUtils],
  components: {
    Table,
    Modal,
    FormInputText,
    FormInputSelect,
    FormInputDate,
    FormMessage,
    NotificationPopup,
  },

  data() {
    return {
      budgets: [],
      newBudgetErrorMessage: null,
    }
  },

  mounted: function() {
    this.buildTable();

    var date = new Date();
    date.setMonth(date.getMonth() + 12)
    this.$refs.endDate.setValue(date)
    date.setMonth(date.getMonth() - 24)
    this.$refs.startDate.setValue(date)

    this.loadBudgets();
  },

  methods: {

    loadBudgets: function(){
      this.get('/budgets', {
        'start_date': this.$refs.startDate.getValue(), 
        'end_date': this.$refs.endDate.getValue(),
        'no_data': true
      }, function(budgets){
        this.budgets = budgets;
        this.$refs.table.items = this.budgets;
      }.bind(this), function(error){
        console.log(error)
      }.bind(this))
    },


    deleteBudgets: function(){
      this.$refs.confirmDeleteModal.close();
      let selected_ids = this.$refs.table.selected;

      for (var id of selected_ids) {
        this.delete('/budgets/' + id, function(){
          this.$refs.popup.open("Budget(s) deleted.", false);
          this.loadBudgets();
        }.bind(this), function(error){
          this.$refs.popup.open("Unexpected error deleting budgets.", true);
          console.log("Error Deleting budgets", error)
        }.bind(this))
      }

      this.$refs.table.deselectAll();
    },

    addNewBudget: function(){
      var valid = this.validateNewBudgetFields();

      if(valid){
        var copy_id = null;
        var copyBudgetName = this.$refs.copyBudget.getValue();
        var copy_budget = this.budgets.filter(x => x.name == copyBudgetName)[0];
        if (copy_budget) {
          copy_id = copy_budget.id;
        }
        
        var budget = {
          name: this.$refs.budgetNameInput.getValue(),
          start_date: this.$refs.startDateInput.getValue(),
          end_date: this.$refs.endDateInput.getValue(),
          categories: [],
          copy_id: copy_id
        }

        this.$refs.newBudgetMessage.setMessage("Creating Budget...");

        this.post('/budgets', budget, function(){
          this.$refs.popup.open(budget.name + " added.", false);
          this.$refs.addBudgetModal.close();
          this.loadBudgets();
        }.bind(this), function(error){
          console.log(error);
          this.$refs.newBudgetMessage.setErrorMessage(error)
        }.bind(this))
      }

    },

    validateNewBudgetFields: function(){
      var valid = true;
      var name = this.$refs.budgetNameInput.getValue();
      var start_date = this.$refs.startDateInput.getValue();
      var end_date = this.$refs.endDateInput.getValue();

      if(this.fieldIsEmpty(name) || this.fieldIsEmpty(start_date) || 
          this.fieldIsEmpty(end_date)) {
        this.$refs.newBudgetMessage.setErrorMessage('Missing required fields')
        valid = false;
      }

      return valid;
    },

    fieldIsEmpty: function(value) {
      return !value || value == ""
    },


    budgetItemSelected: function(budget){
      this.$emit('open-budget', budget.id)
    },

    showDeleteConfirm: function(){
      this.$refs.confirmDeleteModal.open();
    },

    showAddBudgetModal: function(){
      this.$refs.addBudgetModal.open();
      this.newBudgetErrorMessage = null;
    },
    
    buildTable: function(){

      this.$refs.table.columns = [
        { label: "Name", attr: "name" },
        { label: "Planned", attr: "amount_budgeted", display: "money", hideOnMobile: true},
        { label: "Spent", attr: "amount_spent", display: "money", hideOnMobile: true},
        { label: "Start", attr: "start_date" },
        { label: "End", attr: "end_date" },
      ];

    },
  
  },

  computed: {
    copyBudgetOptions: function() {
      return this.budgets.map(x => x.name);
    },
  }

}
</script>

<style scoped>

.header-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  align-items: center;
}

.add-button-container{
  margin: 0.67em 0;
}

.delete-confirm-text{
  margin-top: 1.0em;
  margin-bottom: 1.67em;
}

.align-bottom{
  margin-top: auto;
}

</style>
