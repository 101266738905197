<template>
    <div>

        <div class="action-container">

            <!-- BULK ACTION CONTAINER -->
            <div class="icon-button-container">
                <IconButton :icon="'far fa-square'" v-if="!allSelected" @click='selectAll' title='Select All'></IconButton>
                <IconButton :icon="'fas fa-check-square'" v-if="allSelected" @click='deselectAll' title='Select All'></IconButton>
                <IconButton :icon="'fas fa-redo'" @click="$emit('refresh')" title='Refresh'></IconButton>
                <IconButton :icon="'fas fa-filter'" @click='toggleFilter' title='Toggle Filters'></IconButton>
                <div v-if="anItemIsSelected" class="icon-button-container">
                    <IconButton :icon="'fas fa-trash-alt'" @click="$emit('delete-items')"></IconButton>
                </div>

            </div>

            <!-- PAGINTION CONTAINER -->
            <div class="icon-button-container pagination-container">
                <IconButton :icon="'fas fa-chevron-left'" @click='pageDown'></IconButton>
                <span> {{ page }} / {{ Math.ceil(items.length / itemsPerPage) }} of {{ items.length }} </span>
                <IconButton :icon="'fas fa-chevron-right'" @click='pageUp'></IconButton>
            </div>

        </div>

        <!-- FILTER CONTAINER -->
        <div class='filter-container' :class="{'hidden': !showFilterSlot}">
            <slot></slot>
        </div>

        <table>

            <!-- TABLE HEADER -->
            <tr class="table-row">
                <td class="table-column"></td>
                <td v-for="column in columns" :key="column.id" 
                    class="table-header-column" 
                    :class="{'hide-on-mobile':column.hideOnMobile}"
                    @click="sort(column)">
                    {{ column.label }}
                    <i class="fas fa-sort"></i>
                </td>
            </tr>

            

            <!-- TABLE CONTENT -->
            <tr v-for="item in itemsOnPage" :key="item.id" class="table-row" 
                v-bind:class="{ 'selected-row': isItemSelected(item), 'clickable-row': clickable }"
                @click="clickItem($event, item)"
                @click.right='openItemContextMenu($event, item)'
                @dblclick="dblclickItem(item)">
                
                <td class="table-column">
                    <input type="checkbox" :checked="isItemSelected(item)">
                </td>

                <td v-for="column in columns" :key="column.id" class="table-column"
                    :class="{'hide-on-mobile':column.hideOnMobile, 'no-wrap': column.noWrap}">
                    <div v-if='column.display == "money"'> {{ formatMoney(item[column.attr] / 100) }} </div>
                    <div v-if='!column.display'> {{ item[column.attr] }} </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

import IconButton from './IconButton.vue'

import FormatUtils from '../utilities/FormatUtils'

export default {

    mixins: [FormatUtils],
  
    components: {
        IconButton,
        // ContextMenu,
    },

    props: {
        pagination: Boolean,
        itemsPerPage: Number,
        itemKey: String,
        itemContextOptions: Array,
        bulkItemContextOptions: Array,
        clickable: Boolean,
    },

    data() {
        return {
            // PUBLIC
            items: [],
            columns: [],
            selected: [],
            
            allSelected: false,
            page: 1,
            previousSortAttr: null,
            showFilterSlot: true,
        }
    },

    mounted: function() {

    },

    methods: {

        // EVENT HANDLING

        toggleFilter: function(){
            this.showFilterSlot = !this.showFilterSlot;
        },

        showBulkItemContextMenu: function(){
            this.$refs.bulkItemContextMenu.toggle();
        },

        openItemContextMenu: function(event, item){

            this.$refs.itemContextMenu.show();
            if(this.anItemIsSelected){
                this.$refs.bulkItemContextMenu.hide();
            }

            this.selected = [item[this.itemKey]];
            this.$refs.itemContextMenu.x = event.clientX;
            this.$refs.itemContextMenu.y = event.clientY;
        },

        dblclickItem: function(event){
            if(this.clickable){
                this.$emit('dblclick-item', event[this.itemKey]);
            }
        },

        clickItem: function(event, item){
            if(event.srcElement.type && event.srcElement.type === "checkbox"){
                this.select(event, item);
            } else if (this.clickable) {
                this.$emit('click-item', item);
            }
        },

        // FIELD SORTING

        sort: function(column){
            let attr = column.attr;
            this.items.sort(function(a, b){
                if(a[attr] < b[attr]) { return -1; }
                if(a[attr] > b[attr]) { return 1; }
                return 0;
            });

            if(attr === this.previousSortAttr){
                this.items.reverse();
                this.previousSortAttr = null;
            } else {
                this.previousSortAttr = attr;
            }

            if(this.page != 1){
                this.page = 1
            }
        },

        // ITEM SELCTION

        select: function(event, item){
            let key = item[this.itemKey];

            if(event.target.checked === true){
                this.selected.push(key);
            } else if (event.target.checked === false){
                this.selected = this.selected.filter(e => e !== key);
            }
        },

        isItemSelected: function(item){
            return this.selected.includes(item[this.itemKey]);
        },

        selectAll: function(){
            this.allSelected = true;
            this.selected = this.items.map(e => e[this.itemKey]);
        },

        deselectAll: function(){
            this.selected = [];
            this.allSelected = false;
        },

        //PAGINATION

        pageUp: function(){
            if(this.page < Math.ceil(this.items.length / this.itemsPerPage)){
                this.page++;
            }
        },

        pageDown: function(){
            if(this.page > 1){
                this.page--;
            }
        },


    },

    computed: {
        itemsOnPage: function(){
            if(!this.pagination){
                return this.items;
            }

            let start = (this.page - 1) * this.itemsPerPage;
            let end = this.page * this.itemsPerPage;
            return this.items.slice(start, end);
        },

        anItemIsSelected: function(){
            return this.selected.length > 0;
        }
    }
}
</script>

<style scoped>

    .action-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid var(--border-secondary);
        font-size: 13pt;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .icon-button-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .pagination-container{
        font-size: 13pt;
    }
    
    table{
        border-collapse: collapse;
        width: 100%;
    }

    td{
        padding: 5px;
        border-bottom: 1px solid var(--border-primary);
    }

    .selected-row{
        background-color: var(--highlight-primary);
    }

    .table-header-column{
        padding: 10px;
        font-weight: bold;
    }

    .table-header-column:hover{
        cursor: pointer;
    }

    input[type="checkbox"]{
        width: 11pt;
        height: 11pt;
        margin: 0px;
    }

    .clickable-row{
        cursor: pointer;
    }

    .filter-container{
        border-bottom: 1px solid var(--border-secondary);
        padding-bottom: 0.67em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        
    }

    .filter-container div{
        margin-right: 7px;
    }

    .no-wrap{
        white-space: nowrap;
    }
</style>
