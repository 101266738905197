
<template>
    <div>
        <i class="fas fa-grip-vertical grip font-primary" @mousedown="_startDrag"></i>
        <div v-show="moving" class="draggable" 
            :class="{'moving': moving}"
            :style="{'top': x + 'px', 'left': y + 'px'}">
            {{ message }}
        </div>
    </div>
</template>
<script>
export default{

    props: {
        message: String
    },

    data() {
        return {
            moving: false,
            x: null,
            y: null,
        }
    },

    methods: {

        _startDrag: function(event){
            document.addEventListener('mousemove', this._handleDrag);
            document.addEventListener('mouseup', this._endDrag);
            this.moving = true;
            this._handleDrag(event);
        },

        _handleDrag(event) {
            event.preventDefault();
            this.x = event.clientY - 3;
            this.y = event.clientX - 3;
        },

        _endDrag(event) {
            document.removeEventListener('mousemove', this._handleDrag);
            document.removeEventListener('mouseup', this._endDrag);
            this.moving = false;

            /* get the target element below the draggable div */
            this.$nextTick( () => {
                let target = document.elementFromPoint(event.clientX, event.clientY);
                this.$emit('drag-ended', target);
            })
            
        },
    }
}
</script>
<style>
    .grip:hover{
        cursor: grab;
    }

    .grip{
        margin: 0px 10px;
        opacity: 0.25;
    }

    .moving{
        cursor: grabbing;
    }

    .draggable{
        position: fixed;
        background-color: var(--background-secondary);
        border: 1px solid var(--border-secondary);
        padding: 10px 15px;
        border-radius: 5px;
        z-index: 100;
        box-shadow: 0 8px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
</style>