<template>
    <div>
        <div class='form-input-label'>
            {{label}}
            <span v-if='required' class='required-asterisk'>*</span>
        </div>

        <input type="text" class='form-input form-input-text' :placeholder='placeholder' ref="input" :autocomplete="autocomplete">
    </div>
</template>

<script>

export default {
  props: ['label','required','placeholder', 'autocomplete'],

  methods: {
      getValue: function(){
          return this.$refs.input.value
      },

      setValue: function(value){
          return this.$refs.input.value = value
      },

      reset: function(){
          this.$refs.input.value = ''
      },

      focus: function(){
          this.$refs.input.focus();
      }
  }
}
</script>

<style>
.form-input-text{
    padding-bottom: 9px !important;
    padding-top: 9px !important;
}
</style>
