<template>
    <div>
        <div v-if='errorMessage' class='msg font-red'>{{errorMessage}}</div>
        <div v-if='message' class='msg'>{{message}}</div>
    </div>
</template>

<script>

export default {

  data() {
      return {
          errorMessage: '',
          message: '',
      }
  },

  methods: {

    setErrorMessage: function(errorMessage){
        this.clearMessages();
        this.$nextTick(function(){
            this.errorMessage = errorMessage;
        }.bind(this));
    },

    setMessage: function(message){
        this.clearMessages();
        this.$nextTick(function(){
            this.message = message;
        }.bind(this));          
    },

    clearMessages: function(){
        this.message = '';
        this.errorMessage = '';
    }
  }
}
</script>

<style scoped>

.msg{
    padding-top: 1em;
    animation:  shake 0.4s;
    text-align: center; 
  }
    
@keyframes shake{ 
    0%{ 
        transform: translateX(0) 
    } 
    25%{ 
        transform: translateX(15px); 
    } 
    100%{ 
        transform: translateX(0px); 
    } 

} 

</style>
