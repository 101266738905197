<template>
    <div class='container'>
        <select ref='input' class='bread-crumb-select' @change='$emit("change")'>
            <option v-for='option in options' :key="option.value" :value='option.value'>
                {{ option.label }}
            </option>
        </select>
        <i class="fas fa-chevron-down"></i>
    </div>
</template>

<script>

export default {

  data() {
      return {
          options: Array
      }
  },

  methods: {
      getValue: function() {
          return this.$refs.input.value;
      },
      setValue: function(value) {
          this.$refs.input.value = value;
      }
  }
}
</script>

<style scoped>

.container{
    border-bottom: 2px solid transparent;
    margin-left: 7px;
}

.container:hover{
    border-bottom: 2px solid var(--font-primary);
}

.bread-crumb-select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 13pt;
    border: none;
    padding: 4px;
    outline: none;
    background: none;
    color: var(--font-primary);
}

</style>