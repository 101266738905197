<template>
    <div v-if='show' class="modal-container" @keyup.enter="submit">
        <div class="modal background-primary shadow-2">

            <div class="modal-header-container">
                <div class="modal-title">{{title}}</div>
                <div class="modal-cancel-button">
                    <IconButton :icon="'fas fa-times'" @click="close"> </IconButton>
                </div>
            </div>

            <slot></slot>

            <div class="modal-button-container">
                <button @click='close' class='cancel-button btn btn-secondary'>Cancel</button>
                <button :class='submitButtonClass' @click='submit'>{{ submitLabel }}</button>
            </div>

        </div>
    </div>
    
</template>

<script>

import IconButton from './IconButton.vue'

export default {

  components: {
    IconButton,
  },

  props: ['title','submitLabel','submitButtonClass', 'focusInput'],

  data() {
      return {
          show: false,
          errorMessage: '',
      }
  },



  methods: {
      close: function(){
          this.show = false;
      },

      open: function(){
        this.show = true;  

        this.$nextTick(function(){
            var inputs = this.$el.getElementsByClassName('form-input');
            if (inputs.length > 0){
                inputs[0].focus();
            }
        }.bind(this));
      },

      setErrorMessage: function() {

      },

      submit: function(){
          this.$emit('submit');
      }
  }
}
</script>

<style scoped>

.modal{
    width: 22em;
    min-height: 10em;
    border-radius: 1em;
    padding: 25px;
    margin: auto;
    margin-top: 15vh;
}

.modal-container{
    background-color: var(--focus-filter-color);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.modal-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-bottom: 8px;
}

.cancel-button{
    margin-right: 12px;
}

.modal-title{
    font-size: 16pt;
    font-weight: bold;
}

.modal-button-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--border-secondary);
    
}

</style>
