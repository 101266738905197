<template>
    <div>
        <div class='form-input-label'>
            {{label}}
            <span v-if='required' class='required-asterisk'>*</span>
        </div>

        <input type="date" class='form-input' 
            :placeholder='placeholder' 
            ref="input" 
            @change="$emit('change')">
    </div>
</template>

<script>

export default {
  props: {
      label: String,
      required: Boolean,
      placeholder: String,
      initialDate: String,
  },

  mounted: function() {
      this.setValue(this.initialDate);
  },

  methods: {
      getValue: function(){
          return this.$refs.input.value
      },

      setValue: function(value){
          if(typeof(value) === 'object'){
              var date = value
              var dateString = date.toISOString().substr(0, 10)
              this.$refs.input.value = dateString
          } else {
              this.$refs.input.value = value
          }
      },

      reset: function(){
          this.$refs.input.value = ''
      },
      
      focus: function(){
          this.$refs.input.focus();
      }
  }
}
</script>
