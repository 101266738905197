<template>
    <div class="header-container font-light">
      <!-- HEADER LOGO -->
      <div class="menu-btn" >
        <IconButton label="" icon="fas fa-bars hide-on-mobile" @click="toggleMenu"></IconButton>
      </div>

      <!-- HEADER ICON BUTTONS -->
      <div class="icon-button-container">
        <IconButton v-if="theme === 'light'" icon="fas fa-moon" @click='toggleDarkMode'></IconButton>
        <IconButton v-if="theme === 'dark'" icon="fas fa-sun" @click='toggleDarkMode'></IconButton>
        <IconButton :label="username" icon="far fa-user-circle" @click="toggleUserOptionsMenu">
          <ContextMenu class='user-menu font-primary' ref='userMenu' :options="userOptions"></ContextMenu>
        </IconButton>
      </div>
    </div>
</template>

<script>
import IconButton from './IconButton.vue'
import ContextMenu from './ContextMenu.vue'

export default {
  name: 'Header',
  components: {
    IconButton,
    ContextMenu,
  },

  props: ['username','theme'],

  data() {
    return {
      userOptions: [
        {label: 'Logout', callback: this.logout.bind(this)}
      ]
    }
  },

  methods: {

    logout: function(){
      console.log("logout");
      this.$emit("logout");
    },

    toggleMenu: function(){
      this.$emit('toggleMenu');
    },

    toggleDarkMode: function(){
      this.$emit('toggleDarkMode');
    },

    toggleUserOptionsMenu: function(){
      this.$refs.userMenu.toggle();
    }
  },
  
}
</script>

<style scoped>

  .user-menu{
    right: 10px;
  }

  .menu-btn{
    font-size: 15pt;
  }

  .header-container{
    background-color: var(--background-primary);
    border-bottom: 1px solid var(--border-secondary);
    color: var(--font-primary);
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .icon-button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12pt;
  }
</style>
