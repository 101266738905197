<template>
    <div>
        <div class='form-input-label'>
            {{label}}
            <span v-if='required' class='required-asterisk'>*</span>
        </div>

        <input type="password" class='form-input' ref="input" :autocomplete="autocomplete">
    </div>
</template>

<script>

export default {
  props: ['label','required','placeholder','autocomplete'],

  methods: {
      getValue: function(){
          return this.$refs.input.value
      },

      reset: function(){
          this.$refs.input.value = "";
      }
  }
}
</script>
