<template>
    <div>
        <h1>Settings</h1>
        <p>Nothing here yet. Coming soon...</p>
    </div>
</template>

<script>



export default {
  components: {

  },

  data() {
    return {

    }
  },

  methods: {

  }

}
</script>

<style scoped>

</style>
