<template>
    <div>
        <h1>Spending By Day</h1>
        <line-chart :data="spendingChartData" :curve="true"
            class="spending-chart line-chart">
        </line-chart>
    </div>
</template>

<script>

export default {

  props: {
    transactions: Array,
  },

  computed: {
    spendingChartData: function() {
      var data = {};
      
      for (var transaction of this.transactions){
        //TODO include year label if necessary
        var date_label = transaction.date.substring(5,7) + "/"
          + transaction.date.substring(8,10);

        if (data[date_label]){
          data[date_label] += transaction.amount / 100; 
        } else {
          data[date_label] = transaction.amount / 100;
        }
      }

      data = Object.keys(data).sort().reduce(
        (obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {}
      );
      
      return data;
    },
  }

}
</script>

<style scoped>

.spending-chart{
  padding: 12px;
}

</style>
