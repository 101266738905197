<template>
    <div>
        <NotificationPopup ref='popup'></NotificationPopup>

        <div class="popup-btn-mobile" v-if="hidden">
            <IconButton :icon="'fas fa-plus'" @click="toggleShowHide"></IconButton>
        </div>

        <div class="popup background-primary" :class="{'popup-hidden' : hidden}">

            <div class='popup-header' @click="toggleShowHide">
                <div>New Transaction</div>
                
                <IconButton v-if="hidden" :icon="'fas fa-plus'"></IconButton>
                <IconButton v-if="!hidden" :icon="'fas fa-times'"></IconButton>
            </div>
            <FormInputDate ref="dateInput" :label='"Date"' :required="true" @change="getBudgetCategories"></FormInputDate>
            <FormInputText ref="memoInput" :label='"Memo"' :required="true"></FormInputText>
            <FormInputText ref="amountInput" :label='"Amount"' :required="true"></FormInputText>
            <FormInputSelect ref="subCategoryInput" :label='"Sub-Category (Category)"' :required="true" 
                :disabled='!subCategoryOptionsLoaded' :options="subCategoryOptions">
            </FormInputSelect>
            <FormMessage ref="message"></FormMessage>
            <div class="form-submit-container">
                <button class='btn btn-secondary cancel-btn' @click='hide'>Cancel</button>
                <button class='btn btn-primary' @click="submit">Submit</button>
            </div>
        </div>
    </div>
    
</template>

<script>

import IconButton from './IconButton.vue'
import FormInputText from './FormInputText.vue'
import FormInputDate from './FormInputDate.vue'
import FormInputSelect from './FormInputSelect.vue'
import FormMessage from '../components/FormMessage'
import ServiceUtils from '../utilities/ServiceUtils'
import NotificationPopup from '../components/NotificationPopup'

export default {
  mixins: [ServiceUtils],
  components: {
    IconButton,
    FormInputText,
    FormInputDate,
    FormInputSelect,
    FormMessage,
    NotificationPopup,
  },


  data() {
      return {
          hidden: true,
          subCategoryOptionsLoaded: false,
          subCategoryOptions: []
      }
  },

  methods: {

      submit: function(){
        this.$refs.message.clearMessages();

        var date = this.$refs.dateInput.getValue();
        var memo = this.$refs.memoInput.getValue();
        var amount = this.$refs.amountInput.getValue();
        var categoryName = ""
        var subCategoryName = ""

        var subCategoryInputValue = this.$refs.subCategoryInput.getValue()
        for (var option of this.subCategoryOptions){
            if (option.label === subCategoryInputValue) {
                categoryName = option.categoryName
                subCategoryName = option.subCategoryName
                break;
            }
        }

        if (!date || date == "" || !memo || memo == "" || !amount || amount == "" ||
            !subCategoryInputValue || subCategoryInputValue == ""){
            this.$refs.message.setErrorMessage('Missing required fields')
            return
        }

        if (Number.isNaN(Number(amount))) {
            this.$refs.message.setErrorMessage('Amount must be a number');
            return;
        }
        
        var transaction = {
            'date': date,
            'memo': memo,
            'amount': amount * 100,
            'category': categoryName,
            'sub_category': subCategoryName, 
        }

        this.post('/transactions', transaction, function(){
            this.$refs.popup.open("Transaction added.", false);
            this.$refs.dateInput.focus();
            this.$emit('transaction-added');
        }.bind(this), function(error){
            this.$refs.popup.open("Failed to add transaction.", true);
            console.log(error);
        }.bind(this))
      },

      getBudgetCategories: function(){
        
        this.subCategoryOptionsLoaded = false;

        this.get('/budgets', {
            'date': this.$refs.dateInput.getValue(), 
        }, function(budgets){
            this.subCategoryOptions = []

            for (var budget of budgets){ //TODO handle multiple budgets
                for (var category of budget.categories) {
                    for (var sub_category of category.sub_categories) {
                        this.subCategoryOptions.push({
                            label: sub_category.name + " (" + category.name + ")",
                            categoryName: category.name,
                            subCategoryName: sub_category.name
                        })
                    }
                }
            }

            this.subCategoryOptionsLoaded = true;
            
        }.bind(this), function(error){
            this.$refs.popup.open("Failed to load sub categories.", true);
            console.log(error)
        }.bind(this))
      },

      toggleShowHide: function(){
          if(this.hidden){
              this.show();
          } else{
              this.hide();
          }
      }, 

      show: function(){
        this.hidden = false;
        this.$refs.dateInput.focus();
      },

      hide: function(){
        this.hidden = true;
      }
  }
}
</script>

<style scoped>

.cancel-btn{
    margin-right: 7px;
}

.form-submit-container{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.popup-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border-secondary);
    cursor: pointer;
}

.popup-hidden{
    position: fixed;
    bottom: -22.5em !important;
    background-color: var(--background-secondary);
}

.popup{
    width: 24em;
    border-radius: 1em 1em 0 0;
    padding: 17px;
    position: fixed;
    bottom: 0;
    transition: bottom 0.1s ease-in-out;
    right: 10%;
    box-shadow: 0 8px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.popup-btn-mobile{
    display: none;
}

@media screen and (max-width: 600px) {
    .popup-hidden{
        bottom: -28em !important;
        transition: bottom 0.1s ease-in-out;
    }

    .popup{
        width: calc(100vw - 32px);
        left: 0;
        bottom: 3em;
    }

    .popup-btn-mobile{
        display: block;
        position: fixed;
        left: calc(100vw - 4em);
        bottom: 5em;
        z-index: 100;
        padding: 14px 5px;
        border-radius: 16px;
        font-size: 12pt;
        background-color: #1c53c0;
        color: white;
        box-shadow: 0 8px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }

</style>
