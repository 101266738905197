import Vue from 'vue'
import App from './App.vue'

/*Register external Vue components*/

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);

import Chart from 'chart.js/dist/chart.min.js';
import Chartkick from 'vue-chartkick';
Vue.use(Chartkick.use(Chart));

new Vue({
  render: h => h(App),
}).$mount('#app')

