<template>
    <div>
        <div v-if='!disabled' @click="$emit('click')" class="button-container">
            <i v-bind:class="icon"></i>
            <span v-if="label" class="label">{{label}}</span>    
        </div>
        <div v-if='disabled' class="disabled-button-container font-disabled">
            <i v-bind:class="icon"></i>
            <span v-if="label" class="label">{{label}}</span>    
        </div>
        <slot></slot>
    </div>
    
    
</template>

<script>

export default {
  props: ['icon','label','disabled']
}
</script>

<style scoped>

.button-container{
    padding: 0px 10px;
}

.disabled-button-container{
    padding: 0px 10px;
}

.button-container:hover{
    cursor: pointer;
    opacity: 0.7;
}

.button-container:active{
    opacity: 0.50;
}

.label{
    padding: 0px 7px;
}

.label:hover{
    text-decoration: underline;
}
</style>
