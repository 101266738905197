<template>
    <div class="row" :class="{'selected-row': selected}">
        
        <div class="small-column hide-on-mobile">
            <Draggable :message='"Move \"" + sub.name + "\""' ref="draggable" @drag-ended="_endDrag"></Draggable>
        </div>
        <div class="small-column">
            <input type="checkbox" v-model="selected">
        </div>
        <div class="column">
            <FormInputTableCell ref="nameInput" :type="'text'"
                @change="_changedName"></FormInputTableCell>
        </div>
        <div class="column">
            <FormInputTableCell ref="budgetedInput" :type="'monetary'"
                @change="_changeBudgetedAmount"></FormInputTableCell>
        </div>
    
        <div class="column hide-on-mobile">{{formatMoney(sub.amount_spent / 100)}}</div>
        <div class="column" :class="{'font-red': totalRemaining < 0, 'font-green': totalRemaining >= 0}">
            {{formatMoney(totalRemaining)}}
        </div>
        <div class='column link-button hide-on-mobile' @click='_openTransactions'>transaction(s)</div>
    </div>
</template>
<script>
import FormatUtils from '../utilities/FormatUtils'

import IconButton from './IconButton'
import FormInputTableCell from './FormInputTableCell'
import Draggable from './Draggable'


export default{
    mixins: [FormatUtils],

    components: {
        IconButton,
        FormInputTableCell,
        Draggable
    },

    props: {
        sub: Object
    },

    data() {
        return {
            selected: false,
        }
    },

    mounted(){
        this._loadInitialValues();
    },

    updated(){
        this._loadInitialValues();
    },

    methods: {
        /*public methods*/

        select: function(){
            this.selected = true;
        },

        deselect: function(){
            this.selected = false;
        },

        /*private methods*/

        _loadInitialValues: function(){
            this.$refs.nameInput.setInitialValue(this.sub.name);
            this.$refs.budgetedInput.setInitialValue(this.sub.amount_budgeted);
        },

        _changedName: function(name){
            this.$emit('change-name', name, this.sub);
        },

        _changeBudgetedAmount: function(amount){
            this.$emit('change-budgeted-amount', amount, this.sub);
        },

        _openTransactions: function(){
            this.$emit('open-transactions', this.sub);
        },

        _endDrag(target) {
            this.$emit('drag-ended', target, this.sub);
        },
    },

    computed: {
        totalRemaining: function(){
            return (this.sub.amount_budgeted - this.sub.amount_spent) / 100;
        },
    }
}

</script>
<style scoped>

.row{
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid var(--border-secondary);
}

.small-column{
    flex: 1;
}

.column{
    flex: 8;
}

.selected-row{
    background-color: var(--highlight-primary);
}
</style>