<template>
  <div id="app" >

    <!-- LOGIN PAGE -->
    <div v-if='!userLoggedIn'>
      <LoginView @login="login"></LoginView>
    </div>

    <!-- MAIN APPLICATION PAGE -->
    <div v-if="userLoggedIn">


      <SideNavigation ref='sideNavigation'
        :navigationItems="[
          {label: 'About', icon: 'fas fa-info-circle'},
          {label: 'Dashboard', icon: 'fas fa-chart-line'},
          {label: 'Budgets', icon: 'fas fa-list'},
          {label: 'Transactions', icon: 'fas fa-receipt'},
          {label: 'Settings', icon: 'fas fa-cog'},
        ]"
        :intialSelected="view"
        @item-selected="changeView">
      </SideNavigation>


      <div class="main" :class="{'minimized-main': minimized, 'not-minimized-main': !minimized}">
        <Header 
          :username="username"
          :theme="theme"
          @toggleMenu='toggleMenu'
          @toggleDarkMode='toggleDarkMode'
          @logout="logout">
        </Header>

        <div class="view">
          <WelcomePageView :class="{'hidden': view != 'About'}"></WelcomePageView>
          <DashboardView :class="{'hidden': view != 'Dashboard'}"
            @open-transactions="openTransactions">
          </DashboardView>
          <BudgetView :class="{'hidden': view != 'Budget'}" ref='budget'
            @open-transactions="openTransactions"
            @back="changeView('Budgets')">
          </BudgetView>
          <BudgetsView :class="{'hidden': view != 'Budgets'}" ref='budgets'
            @open-budget="openBudget">
          </BudgetsView>
          <TransactionsView :class="{'hidden': view != 'Transactions'}" ref='transactions' 
            :filterOptions="transactionFilterOptions"
            @add-new-transaction="openTransactionForm">
          </TransactionsView>
          <SettingsView :class="{'hidden': view != 'Settings'}"></SettingsView>
        </div>

      </div>




      <TransactionPopupForm ref='transactionForm' @transaction-added="transactionAdded"></TransactionPopupForm>
    </div>

    
  </div>
</template>

<script>
import Header from './components/Header.vue'
import SideNavigation from './components/SideNavigation.vue'

import BudgetView from './views/BudgetView.vue'
import BudgetsView from './views/BudgetsView.vue'
import TransactionsView from './views/TransactionsView.vue'
import LoginView from './views/LoginView.vue'
import WelcomePageView from './views/WelcomePageView.vue'
import SettingsView from './views/SettingsView.vue'
import DashboardView from './views/DashboardView.vue'

import TransactionPopupForm from './components/TransactionPopupForm.vue'
import ServiceUtils from './utilities/ServiceUtils'

export default {
  mixins: [ServiceUtils],

  components: {
    Header,
    SideNavigation,

    BudgetView,
    BudgetsView,
    TransactionsView,
    LoginView,
    WelcomePageView,
    SettingsView,
    DashboardView,

    TransactionPopupForm,
  },

  data() {
    return {
      view: '',
      previousView: '',
      userLoggedIn: false,
      theme: 'light', 
      username: "Loading...",
      transactionFilterOptions: {},
      minimized: false
    }
  },

  mounted: function() {
    this.loadSystemTheme();

    var session_id = this.getCookie('session_id');
    var expires = this.getCookie('expires');
    if(!session_id || Date() > Date(expires)) {
      this.userLoggedIn = false;
    } else {
      this.login();
    }
  },

  methods: {

    loadUsername(){
      this.get('/users', {}, function(user){
        this.username = user.email        
      }.bind(this), function(){
        this.$refs.popup.open("Error loading user profile", true);
      }.bind(this))
    },

    openTransactions: function(filterOptions){
      this.$refs.transactions.setFilterOptions(filterOptions)
      this.$refs.transactions.loadTransactions()
      if(this.$refs.sideNavigation){
        this.$refs.sideNavigation.setSelected("Transactions"); //TODO find a better way to bind
      }
      this.changeView('Transactions');
    },

    login: function(){
      this.userLoggedIn = true;
      this.loadUsername();
      this.changeView('Dashboard');
    },

    logout: function(){
      document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      this.userLoggedIn = false;
    },

    changeView: function(view, callback){
      if (this.previousView === "Budget" && view === "Budgets"){
        view = "Budget"
      }

      this.previousView = this.view
      this.view = view;
      this.$nextTick(() => {
        if(callback){
          if(typeof(callback) === 'function'){
            callback();
          }else{
            console.error('Error: callback is not a function');
          }
        }
      });
    },

    toggleMenu: function(){
      this.$refs.sideNavigation.toggle();
      this.minimized = !this.minimized;
    },

    toggleDarkMode: function(){
      this.theme = this.theme === 'light' ? 'dark' : 'light';
      this.loadTheme();
    },

    openBudget: function(budget_id){
      this.changeView('Budget', function(budget_id){
        this.$refs.budget.loadBudget(budget_id)
      }.bind(this, budget_id))
    },

    openTransactionForm: function(){
      this.$refs.transactionForm.show();
    },

    transactionAdded: function(){
      if (this.view == "Transactions") {
        this.$refs.transactions.loadTransactions();
      } else if (this.view == "Budget") {
        this.$refs.budget.loadBudget();
      }
    },

    loadSystemTheme: function(){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          this.theme = 'dark'
      } else {
          this.theme = 'light';
      }
      this.loadTheme();
    },

    loadTheme: function(){
      const themeStylesheet = document.getElementById('theme-stylesheet');

      console.log("theme: " + this.theme);
      // Update the href of the theme stylesheet link
      themeStylesheet.href = `/styles/${this.theme}.css`;
    }
  },

}
</script>

<style scoped>
  @import './assets/styles/main.css';

  .main{
    transition: padding-left 0.2s ease-in-out;
  }

  .minimized-main{
    padding-left: 4em;
  }

  .not-minimized-main{
    padding-left: 12em;
  }

  .view{
    margin-left: 3em;
    margin-right: 3em;
    margin-bottom: 5em;
  }

  @media screen and (max-width: 600px) {
    .minimized-main{
      padding-left: 0;
    }

    .not-minimized-main{
      padding-left: 0;
    }

    .view{
      margin-left: 5px;
      margin-right: 5px;
    }
  }

</style>
