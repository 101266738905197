<template>
  <div class="login-page login-page-background">
      <!-- LOGIN DIALOG -->
      <div class="card background-primary" v-if="view == 'login'" @keyup.enter="login">
        <div class="flex-center">
          <img src="../assets/img/bdgt.site-logo.png" alt="bdgt.site" 
            class="logo-image" width="200px" height="82">
        </div>

        <FormInputText ref='emailInput' :label="'Email Address'"></FormInputText>
        <FormInputPassword ref='passwordInput' :label="'Password'"></FormInputPassword>
        <FormMessage ref='loginMessage'></FormMessage>
        <button class="btn btn-primary submit-button" @click="login" >Sign in</button>
        <div class='link-btn-container font-secondary'>
          <span>Dont have an account? </span>
          <span class='font-link' @click="changeView('create')">Create an account</span>
        </div>
        <div class='btn-link font-link' @click="changeView('reset')"> Forgot password?</div>
        
      </div>

      <!-- ACCOUNT CREATION -->
      <div class="card background-primary" v-if="view == 'create'">
        <div class="flex-center">
          <img src="../assets/img/bdgt.site-logo.png" alt="bdgt.site" 
            class="logo-image" width="200px" height="82">
        </div>
        <div class='header'>Create Account</div>
        <FormInputText ref='emailInput' :label="'Email Address'" :autocomplete="'off'"></FormInputText>
        <FormInputPassword ref='passwordInput' :label="'Password'" :autocomplete="'off'"></FormInputPassword>
        <FormInputPassword ref='confirmPasswordInput' :label="'Confirm Password'" :autocomplete="'off'"></FormInputPassword>
        <FormMessage ref='createAccountMessage'></FormMessage>
        <button class="btn btn-primary submit-button" @click="createAccount">Create Account</button>
        <div class='link-btn-container font-secondary'>
          <span>Already have an account? </span>
          <span class='btn-link font-link' @click="changeView('login')">Sign in</span>
        </div>
      </div>

      <!-- FORGOT PASSWORD -- PART A-->
      <div class="card background-primary" v-if='view == "reset"'>
        <div class='header'>Forgot Password?</div>
        
        <div v-if='!resetEmailSent'>
          <div class='reset-info font-secondary'>Enter you account email and we can send you a reset code.</div>
          <FormInputText ref='emailInput' :label="'Email Address'" :autocomplete="'off'"></FormInputText>
          <FormMessage ref='forgotPwdMessage'></FormMessage>
          <button class="btn btn-primary submit-button" @click='sendResetEmail'>Send Email</button>
        </div>

        <!-- FORGOT PASSWORD -- PART B-->
        <div v-if='resetEmailSent'>
          <FormInputText ref='resetCodeInput' :label="'Reset Code'" :placeholder="'ABC123'" :autocomplete="'off'"></FormInputText>
          <FormInputPassword ref='passwordInput' :label="'New Password'" :autocomplete="'new-password'"></FormInputPassword>
          <FormInputPassword ref='confirmPasswordInput' :label="'Confirm New Password'" :autocomplete="'new-password'"></FormInputPassword>
          <FormMessage ref='forgotPwdMessage'></FormMessage>
          <FormMessage ref='loginMessage'></FormMessage>
          <button class="btn btn-primary submit-button" @click="resetPassword">Reset</button>
          <div v-if='message' class='msg font-secondary'>{{message}}</div>
          <div v-if='errorMessage' class='msg font-red'>{{errorMessage}}</div>
          <div class='link-btn-container font-secondary'>
            <span>Didn't get it? </span>
            <span class='font-link' @click="sendResetEmail">Try again</span>
          </div>
        </div>

        

        <div class='btn-link font-link' @click="changeView('login')">cancel</div>
      </div>

  </div>
</template>

<script>

import FormInputText from '../components/FormInputText'
import FormInputPassword from '../components/FormInputPassword'
import FormMessage from '../components/FormMessage'
import ServiceUtils from '../utilities/ServiceUtils'

export default {
  mixins: [ServiceUtils],
  components: {
    FormInputText,
    FormInputPassword,
    FormMessage
  },

  data() {
    return {
        view: 'login',
        errorMessage: "",
        message: "",
        resetEmailSent: false,
        inputs: [],
        resetEmail: ""
    }
  },

  methods: {

    login: function(email, password){
      this.$refs.loginMessage.setMessage("Signing you in, please wait ...");

      if(email === undefined || password === undefined) {
        email = this.$refs.emailInput.getValue()
        password = this.$refs.passwordInput.getValue()
        if (email == "" || password == ""){
          this.$refs.loginMessage.setErrorMessage("All fields are required")
          return
        }
      }

      var credentials = {}
      credentials.email = email
      credentials.password = password

      this.post('/users/generate-session', credentials,
        function(sessionId){
          var expires = (new Date(Date.now()+ 2*60*60*1000)).toUTCString(); 
          document.cookie = "session_id=" + sessionId + ";expires=" + expires + ";path=/;";
          this.$emit('login');
        }.bind(this),
        function(error){
          this.$refs.loginMessage.setErrorMessage(error);
        }.bind(this)
      )
    },

    createAccount: function(){
        var email = this.$refs.emailInput.getValue()
        var password = this.$refs.passwordInput.getValue()
        var confirm = this.$refs.confirmPasswordInput.getValue()

        if(email != "" && password != "" && confirm != ""){
          if (password == confirm){
            this.post('/users', {
              "email": email,
              "password": password
            }, 
            function(response){
              this.$refs.createAccountMessage.setMessage(response)
              this.login(email, password)
            }.bind(this), function(error){
              this.$refs.createAccountMessage.setErrorMessage(error)
            }.bind(this))
          } else{
            this.$refs.createAccountMessage.setErrorMessage("Passwords do not match.")
          }
        } else {
          this.$refs.createAccountMessage.setErrorMessage("All fields are required.")
        }
    },

    sendResetEmail: function(){
      var email = (this.resetEmailSent)? this.resetEmail : this.$refs.emailInput.getValue()

      if(email != ""){
        this.$refs.forgotPwdMessage.setMessage("Sending...")
        this.post('/users/send-password-reset-code',{"email": email, "password": ""},
          function(response){
            this.$refs.forgotPwdMessage.setMessage(response);
            this.$refs.emailInput.setValue("");
            this.resetEmailSent = true;
            this.resetEmail = email;
          }.bind(this), function(error){
            this.$refs.forgotPwdMessage.setErrorMessage(error);
          }.bind(this))
      }else{
        this.forgotPwdMessage.setErrorMessage("Enter your email.")
      }
    },

    resetPassword: function(){
      var resetCode = this.$refs.resetCodeInput.getValue();
      var password = this.$refs.passwordInput.getValue();
      var confirm = this.$refs.confirmPasswordInput.getValue();
      var email = this.resetEmail;

      if(resetCode != "" && password != "" && confirm != ""){
        if(password == confirm){
          this.post('/users/reset-password',{
              "email": email, 
              "new_password": password, 
              "reset_code": resetCode
            }, function(response){
              // this.$refs.forgotPwdMessage(response);
              this.login(email, password);
            }.bind(this), function(error){
              this.$refs.forgotPwdMessage.setErrorMessage(error);
            }.bind(this))
        }else{
          this.$refs.forgotPwdMessage.setErrorMessage("Passwords do not match.");
        }

      }
    },

    changeView: function(view){
      this.view = view;
      this.errorMessage = ''
      this.message= ''
      this.sendResetEmail;
      if(this.$refs.loginMessage) this.$refs.loginMessage.clearMessages();
      if(this.$refs.createAccountMessage) this.$refs.createAccountMessage.clearMessages();
      if(this.$refs.forgotPwdMessage) this.$refs.forgotPwdMessage.clearMessages();
      if(this.$refs.resetCodeInput) this.$refs.resetCodeInput.reset()
      if(this.$refs.emailInput) this.$refs.emailInput.reset()
      if(this.$refs.passwordInput) this.$refs.passwordInput.reset()
      if(this.$refs.confirmPasswordInput) this.$refs.confirmPasswordInput.reset()
    },


  },

}
</script>

<style scoped>

.header{
    font-size: 16pt;
    padding: 0.4em 0px;
    margin-bottom: 1.25em;
    border-bottom: 1px solid var(--border-secondary);
}

.btn-link{
    margin-top: 16px;
    font-size: 11pt;
    text-align: center;
}

.font-link:hover{
  cursor: pointer;
  text-decoration: underline;
}

.link-btn-container{
  text-align: center;
  font-size: 10pt;
  margin-top: 15px;
  margin-bottom: 15px;
}

.submit-button{
    font-size: 12pt;
    width: fill;
    margin-top: 1.5em;
}

.login-page{
    width: 100vw;
    height: 120vh;
    position: absolute;
    top: 0;
    left: 0;
}

.reset-info {
  padding-bottom: 1em;
}

.card{
    width: 24em;
    border-radius: 1em;
    padding: 25px;
    margin: auto;
    margin-top: 5em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.42), 0 10px 10px rgba(0, 0, 0, 0.42);
}


</style>
