<template>
    <div v-if="visible" class='floating-selector background-secondary' 
        :style="{left: x+'px', top: y+'px'}">
        <div v-for="option in options" :key='option.label' class="selector-option" 
            @click='optionSelected(option)'>
            {{option.label}}
        </div>
    </div>


</template>

<script>

export default {
    props: {
        options: Array,
    },

    methods: {

        optionSelected: function(option){
            if(typeof(option.callback) === 'function'){
                option.callback();
            }
            this.visible = false;
        },

        show: function(){
            this.visible = true;

            var listener = window.addEventListener('mousedown', function(event){
                if(!event.toElement.classList.contains('selector-option')){
                    this.visible = false;
                    window.removeEventListener('mousedown',listener);
                }
            }.bind(this));
        },

        hide: function(){
            this.visible = false;
        },

        toggle: function(){
            if(this.visible){
                this.visible = false;
            }else{
                this.show();
            }
        }
    },

    data() {
        return {
            x: null,
            y: null,

            visible: false,
        }
    },
}
</script>

<style scoped>

.floating-selector{
    margin-top: 5px;
    border-radius: 10px;
    border: 1px solid var(--border-secondary);
    position: absolute;
    min-width: 10em;
    padding: 10px 0px;
}

.selector-option:first-child{
    border: none;
}

.selector-option{
    padding: 8px 1.33em;
    font-size: 12pt;
}

.selector-option:hover{
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}

</style>
