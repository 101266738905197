<template>
  <div class='container'>

    <!-- HEADER ROW -->
    <div class="row">
      <div class="small-column hide-on-mobile">
        <Draggable ref="draggable" 
          :message='"Move \"" + category.name + "\""' 
          @drag-ended="categoryDragged"></Draggable>
      </div>
      <div class="small-column">
          <input type="checkbox" ref='categoryCheckbox' @click="toggleSelection($event)">
      </div>
      <div class="column"> 
        <FormInputTableCell class="category-label" 
          :value="category.name" :type="'text'"
          @change="nameChanged"></FormInputTableCell>
      </div>
      <div class="column font-secondary">budgeted</div>
      <div class="column font-secondary hide-on-mobile">spent</div>
      <div class="column font-secondary">remaining</div>
      <div class="column hide-on-mobile">transactions</div>
    </div>

    <!-- SUB CATEGORIES -->
    <SubCategoryRow v-for="sub in category.sub_categories" 
      ref="subCategoryRows" 
      :key="sub.name" 
      :sub="sub"
      @change-name="subNameChanged"
      @change-budgeted-amount="budgetedValChanged"
      @open-transactions="openTransactions"
      @drag-ended="subCategoryDragged">
    </SubCategoryRow>

    <!-- NEW CATEGORY ROW -->
    <div class="row" v-if="addSubCategoryMode" @keydown.enter="addNewSubCategory">
      <div class="small-column hide-on-mobile"></div>
      <div class="small-column">
        <IconButton :icon='"fas fa-times"' @click='addSubCategoryMode = false'></IconButton>
      </div>
      <div class="column">
        <input type="text" class="form-input category-input"
          ref='newSubCategoryInput' placeholder="new sub-category">
      </div>
      <div class="column flex-row">
        <input type="number" class="form-input amount-input"
          ref='newBudgetedAmountInput' placeholder="$0.00">
        <button class='btn btn-primary btn-small' @click='addNewSubCategory'>Add</button>
      </div>
      <div class="column"></div>
      <div class="column"></div>
      <div class="column"></div>
    </div>

    <!-- TOTALS ROWS -->
    <div class="row totals-row background-secondary">
      <div class="total-row-spacing hide-on-mobile"></div>
      <div class="column new-sub-category-column">
        <button v-if="!addSubCategoryMode"
          class="btn btn-secondary btn-small" 
          @click="showAddNewSubCategoryForm">+ sub-category</button>
      </div>
      <div class="column"> {{ totalBudgeted }}</div>
      <div class="column hide-on-mobile"> {{ totalSpent }}</div>
      <div class="column" :class="{'font-red': totalRemaining < 0, 'font-green': totalRemaining >= 0}">
        {{ formatMoney(totalRemaining) }}
      </div>
      <div class="column hide-on-mobile"></div>
    </div>

  </div>
</template>

<script>

import FormatUtils from '../utilities/FormatUtils'

import IconButton from './IconButton'
import FormInputTableCell from './FormInputTableCell'
import SubCategoryRow from './SubCategoryRow'
import Draggable from './Draggable'

export default {

  mixins: [FormatUtils],

  components: {
    IconButton,
    FormInputTableCell,
    SubCategoryRow,
    Draggable
  },

  props: {
    category: Object,
  },

  data() {
    return {
      selected: Set,
      addSubCategoryMode: Boolean,
      active: []
    }
  },

  created: function() {
    this.selected = new Set();
    this.addSubCategoryMode = false;
  },

  methods: {

    openTransactions: function(sub_category){
      var filterOptions = {
        "category": this.category.name,
        "sub_category": sub_category.name,
      }
      this.$emit('open-transactions', filterOptions);
    },

    //TODO emit delete message instead of mutating property
    nameChanged: function(new_name){
      this.category.name = new_name;
      this.$emit('category-edited');
    },

    //TODO emit message instead of mutating property
    subNameChanged: function(new_name, sub_category){
      sub_category.name = new_name;
      this.$emit('category-edited');
    },

    //TODO emit message instead of mutating property
    budgetedValChanged: function(new_val, sub_category){
      sub_category.amount_budgeted = new_val;
      this.$emit('category-edited');
    },

    getSelected: function(){
      if (this.category.sub_categories.length == 0)
        return [];

      let selected = this.$refs.subCategoryRows
        .filter(row => row.selected === true)
        .map(row => row.sub.name);

      return selected;
    },

    addNewSubCategory: function(){
      var sub_category_already_added = false;
      for(var sub_category of this.category.sub_categories){
        if (sub_category.name == this.$refs.newSubCategoryInput.value){
          sub_category_already_added = true;
        }
      }
      //TODO form validation of new category inputs
      //TODO validate amount budgeted is positive
      if(!sub_category_already_added){
        this.category.sub_categories.push({
          name: this.$refs.newSubCategoryInput.value,
          amount_budgeted: this.$refs.newBudgetedAmountInput.value * 100,
          amount_spent: 0
        });
        this.$refs.newSubCategoryInput.focus();
        this.$refs.newSubCategoryInput.value = '';
        this.$refs.newBudgetedAmountInput.value = '';
        this.$emit('category-edited');
      }

    },

    showAddNewSubCategoryForm: function() {
      this.addSubCategoryMode = true;
      this.$nextTick(() => {
        this.$refs.newSubCategoryInput.focus();
      });
    },

    hideAddNewSubCategoryForm: function() {
      this.addSubCategoryMode = false;
    },

    toggleSelection: function(event){
      if (!this.$refs.subCategoryRows)
        return;

      for (const row of this.$refs.subCategoryRows) {
        if (event.target.checked === true){
          row.select();
        } else {
          row.deselect();
        }
      }
    },

    subCategoryDragged: function(target, sub){
      
      for (const row of this.$refs.subCategoryRows){
        if (row.$el.contains(target)){
          let from = this.category.sub_categories.indexOf(sub);
          let to = this.category.sub_categories.indexOf(row.sub);
          if (from != to){
            const copy = this.category.sub_categories.splice(from, 1)[0];
            this.category.sub_categories.splice(to, 0, copy);
            this.$emit('category-edited');
          }
        }
      }
    },

    categoryDragged: function(target){
      this.$emit('category-dragged', target, this.category);
    }

  },

  computed: {
    totalSpent: function(){
      let total = this.category.sub_categories.reduce((total, sub_category) => {
        return total + sub_category.amount_spent;
      }, 0);

      return this.formatMoney(total / 100);
    },

    totalBudgeted: function(){
      let total = this.category.sub_categories.reduce((total, sub_category) => {
        return total + sub_category.amount_budgeted;
      }, 0);

      return this.formatMoney(total / 100);
    },

    totalRemaining: function(){
      let totalSpent = this.category.sub_categories.reduce((total, sub_category) => {
        return total + sub_category.amount_spent;
      }, 0);

      let totalBudgeted = this.category.sub_categories.reduce((total, sub_category) => {
        return total + sub_category.amount_budgeted;
      }, 0);

      return (totalBudgeted - totalSpent) / 100;
    }

  }


}
</script>

<style scoped>

  .container{
    margin-top: 10px;
  }

  .row{
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid var(--border-primary);
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .totals-row{
    min-height: 1.5em;
  }

  .small-column{
    flex: 1;
  }

  .column{
    flex: 8;
  }

  .category-label{
    font-weight: bold;
    font-size: 14pt;
  }

  .amount-input{
    max-width: 7em;
    margin-right: 8px;
    padding: 5px;
  }

  .category-input{
    max-width: 90pt;
    padding: 6px;
  }

  .total-row-spacing{
    flex: 2;
    min-width: 4em;
  }

  .new-sub-category-column{
    min-width: 100pt;
  }

  @media screen and (max-width: 600px) {
    .new-sub-category-column{
      min-width: 90pt;
      flex: 12;
    }
  }

</style>
