import { render, staticRenderFns } from "./CategorySpendingPieChart.vue?vue&type=template&id=64f68892&scoped=true&"
import script from "./CategorySpendingPieChart.vue?vue&type=script&lang=js&"
export * from "./CategorySpendingPieChart.vue?vue&type=script&lang=js&"
import style0 from "./CategorySpendingPieChart.vue?vue&type=style&index=0&id=64f68892&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f68892",
  null
  
)

export default component.exports