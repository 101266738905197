<template>
  <input type="text" class='table-cell-input' ref="input"
    @change="change"
    :class="{'monetary-cell': type == INPUT_TYPES.MONETARY}">
</template>

<script>

import FormatUtils from '../utilities/FormatUtils'

export default {
  mixins: [FormatUtils],

  props: {
    type: String
  },

  created: function() {
    this.INPUT_TYPES = {
        MONETARY: "monetary",
        TEXT: "text"
    }
  },

  methods: {
    setInitialValue(initialValue){

      switch (this.type){
        case this.INPUT_TYPES.MONETARY:
            this.$refs.input.value = this.formatMoney(initialValue / 100);
            break;
        case this.INPUT_TYPES.TEXT:
            this.$refs.input.value = initialValue;
        default:
            break;
      } 
    },

    change(){
        var output_value = null;

        if (this.type == this.INPUT_TYPES.MONETARY){
            var scrubbed_value = this.$refs.input.value.replace("$","").trim()
            output_value = parseFloat(scrubbed_value) * 100;
            this.$refs.input.value = this.formatMoney(output_value / 100);
        } else {
            output_value = this.$refs.input.value;
        }

        this.$emit('change', output_value);
    },
  }, 
}
</script>

<style>
.table-cell-input{
    border: none;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 2px solid rgba(0,0,0,0.0);
    background: rgba(0,0,0,0.0);
    color: var(--font-primary);
    width: 100pt;
}

.monetary-cell{
  width: 50pt;
}

.table-cell-input:focus, .table-cell-input:hover{
    border-bottom: 2px solid var(--font-primary);
    outline: none;
}
</style>
